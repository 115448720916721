<template>
  <div>
    <v-form style="width:300px;">
      <v-text-field v-model="username" label="邮箱或用户名" required></v-text-field>
      <v-text-field v-model="password" label="密码" required type="password">
      </v-text-field>
      <v-btn block depressed color="primary" style="margin-top:20px;color:white;" @click="onLogin" :loading="isLoading"
        :disabled="isDisabled">
        登录
      </v-btn>
      <div style="display:flex;justify-content:space-between;align-items:center;">
        <v-btn text color="primary" style="margin-top:8px;" @click="redirectRegister">立即注册！
        </v-btn>
        <!-- <v-btn text color="primary" style="margin-top:8px;" @click="redirectForget">忘记密码！
        </v-btn> -->
      </div>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ errText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    data: () => ({
      username: '',
      password: '',
      captcha: '',
      captchaDetail: '',
      snackbar: false,
      errText: '',
      timeout: 2000,
      isLoading: false,
      isDisabled: false,
    }),
    methods: {
      redirectForget() {
        this.$router.push("forget");
      },
      redirectRegister() {
        this.$router.push("register")
      },
      getCaptcha() {
        this.$service.auth.getCaptchaService()
          .then(res => {
            if (res['code'] == 0) {
              this.captchaDetail = res['data'];
            }
          })
      },
      onLogin() {
        this.isLoading = true;
        this.isDisabled = true;
        const jsonData = {
          "username": this.username,
          "password": this.password,
        }
        this.$service.auth.loginService(jsonData)
          .then(res => {
            if (res['code'] == 0) {
              localStorage.setItem("token", res['data']);
              if (localStorage.getItem("redirect")) {
                this.$router.push(localStorage.getItem("redirect"))
                localStorage.removeItem("redirect");
              } else {
                window.location='/user/list';
              }
            } else {
              this.errText = res['message'];
              this.snackbar = true;
            }
            this.isLoading = false;
            this.isDisabled = false;
          })
      }
    },
  }
</script>